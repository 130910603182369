import { graphql } from 'gatsby'

import ProjectPage from 'views/project/ProjectPage'

export const query = graphql`
  query OriginalPageQuery($uid: String!) {
    prismic {
      page: original_page(uid: $uid, lang: "en-us") {
        page_meta_title
        page_meta_description
        page_meta_thumbnail
        title
        tagline
        subtitle
        embed_id
        square_video_webm {
          ... on PRISMIC__FileLink {
            url
          }
        }
        square_video_mp4 {
          ... on PRISMIC__FileLink {
            url
          }
        }
        square_image
        square_imageSharp {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        related_projects {
          project {
            ...PrismicLinkFrag
            ... on PRISMIC_Original_page {
              title
              landscape_video_webm {
                ... on PRISMIC__FileLink {
                  url
                }
              }
              landscape_video_mp4 {
                ... on PRISMIC__FileLink {
                  url
                }
              }
              landscape_image
            }
          }
        }
        body {
          ... on PRISMIC_Original_pageBodyImages_and_text {
            type
            label
            primary {
              main_image
              main_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              aux_image
              aux_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              heading
              content
            }
          }
          ... on PRISMIC_Original_pageBodyHero_quote {
            type
            label
            primary {
              quote
            }
          }
          ... on PRISMIC_Original_pageBodyQuote {
            type
            label
            primary {
              author
              quote
            }
          }
          ... on PRISMIC_Original_pageBodyList {
            type
            label
            fields {
              item
            }
          }
          ... on PRISMIC_Original_pageBodyCredits {
            type
            label
            primary {
              section_title
            }
            fields {
              name
              role
            }
          }
          ... on PRISMIC_Original_pageBodyCards_with_title {
            type
            label
            primary {
              section_title
            }
            fields {
              card
            }
          }
          ... on PRISMIC_Original_pageBodyAwards {
            type
            label
            fields {
              award
            }
          }
        }
      }
    }
  }
`

ProjectPage.query = query
// ProjectPage.fragments = [ProjectFrag, ProjectCardFrag]

export default ProjectPage
